<template>
    <div v-if="topic.zhidaoyu && zhidaoyuDjs"  style="font-size: 30px;width: 100%;" class="terminal-bg-zdy">
        <div class="zdyText">指导语</div>
        <div class="flex flex-child-center" style="margin: 200px auto;width: 1200px">
            <div style="width: 18px;height: 18px;background: #8197FE;border-radius: 50%;margin-right: 20px;"></div>
            <div style="width: 1150px;">{{ topic.zhidaoyu }}</div>
        </div>
        <div class="flex flex-justify-content-center">
            <div class="button-wrap-zdy">
                <!--                <div style="margin-bottom: 30px;color: #2FB2FB;font-size: 36px;font-weight: bold;">-->
                <!--                    <span>{{ zhidaoyuDjs }}s后自动关闭</span>-->
                <!--                </div>-->
                <div class="submit-question" @click="exam_end">
                    <span>确定</span>
                    <img src="../assets/images/submit.png" alt="">
                </div>
            </div>
        </div>
    </div>
    <div class="terminal-bg" v-else>
        <div class="sheet">
            <div class="typeface">
                <img src="../assets/images/zijiada.png" @click="sizeBig">
                <img src="../assets/images/zijianxiao.png" @click="sizeSmall">
            </div>
            <div class="timer" v-if="time_show">
                <img src="../assets/images/shijian.png">
                <span>{{ hourString + ':' + minuteString + ':' + secondString }}</span>
            </div>
            <div class="page-count">{{ topic.now_count }} <span>/{{ topic.all_count }}</span></div>

            <div style="width: 100%;">
                <div class="title" id="title" style="font-size: 24px">
                    <h3 v-html="topic.intro">
                    </h3>
                </div>
                <div class="title"style="font-size: 16px" v-if="topic.type !== 62">
                    <div v-html="topic.title_text">
                    </div>
                </div>
                <div class="options-wrap">
                    <div class="options-list">
                        <!--                        选择-->
                        <template v-if="topic.type === 60 ">
                            <div style="height: 202px;margin-bottom: 50px;"  v-if="topic.title_img">
                                <img :src="topic.title_img" alt="" class="options-img-judgment_answer">
                            </div>
                            <el-radio-group v-model="options"
                                            @change="answer"
                                            :class="(topic.answer || '').length > 4 ? 'radio-wrap' : ''"
                                            id="radio" style="font-size: 20px">
                                <el-radio :label="item.id"
                                          v-for="item in topic.answer"
                                          :key="item.id"
                                          :class="(topic.answer || '').length > 4 ? 'radio' : ''"
                                >
                                    {{ item.text }}
                                </el-radio>
                            </el-radio-group>
                        </template>
                        <!--                        数方块-->
                        <template v-if="topic.type === 57">
                            <div style="margin-bottom: 100px;margin-top: 20px;"  v-if="topic.title_img">
                                <img :src="topic.title_img" alt="" style="display:block;margin: 0 auto; width: 1000px;">
                            </div>
                            <el-radio-group v-model="options"
                                            @change="answer"
                                            :class="(topic.answer || '').length > 4 ? 'radio-wrap' : ''"
                                            id="radio" style="font-size: 20px">
                                <el-radio :label="item.id"
                                          v-for="item in topic.answer"
                                          :key="item.id"
                                          :class="(topic.answer || '').length > 4 ? 'radio' : ''"
                                >
                                    {{ item.text }}
                                </el-radio>
                            </el-radio-group>
                        </template>
                        <!--                        56判断    55数字广度-->
                        <template v-if="topic.type === 59|| topic.type === 61">
                            <div class="options-judgment-box">
                                <div style="height: 202px;">
                                    <img :src="topic.title_img" alt="" class="options-img-judgment" v-if="topic.title_img">
                                </div>
                                <div class="options-judgment-box-answer" style="width: 60%;" :style="topic.title_img ? 'margin-top:100px' : 'margin-top:0'">
                                    <div v-for="(item,index) in topic.answer" :key="index" style="cursor: pointer;position: relative;" @click="answerJudgment(item.id)">
                                        <img src="../assets/images/answerQuestions.png" v-if="options == item.id" class="answerQuestions_image">
                                        <img :src="item.img" alt="" class="options-img-judgment" v-if="item.img">
                                    </div>
                                </div>
                            </div>
                        </template>
                        <!--                        分类测验-->
                        <template v-if="topic.type === 50">
                            <div class="options-judgment-box" style="margin-bottom: 50px;">
                                <div class="options-judgment-box-answer" style="width: 90%;">
                                    <div v-for="(item,index) in topic.answer" :key="index" style="cursor: pointer;position: relative;" @click="answerJudgment(item.id)">
                                        <img src="../assets/images/answerQuestions.png" v-if="options_50 == item.id" class="answerQuestions_image">
                                        <img :src="item.img" alt="" class="options-img-judgment" v-if="item.img">
                                    </div>
                                </div>
                            </div>
                            <el-radio-group
                                v-if="answerList.length>0"
                                v-model="options"
                                @change="answer50"
                                :class="(topic.answer || '').length > 4 ? 'radio-wrap' : ''"
                                id="radio" style="font-size: 20px">
                                <el-radio :label="item.id"
                                          v-for="item in answerList"
                                          :key="item.id"
                                          :class="(answerList || '').length > 4 ? 'radio' : ''"
                                >
                                    {{ item.text }}
                                </el-radio>
                            </el-radio-group>
                        </template>
                        <!--                        图片排列-->
                        <template v-if="topic.type === 64">
                            <div class="options-judgment-box" style="margin-bottom: 50px;margin-top: 30px;">
                                <div class="options-judgment-box-answer" style="width: 90%;">
                                    <!--                                    <div v-for="(item,index) in image_list51" :key="index" style="cursor: pointer;position: relative;" @click="answerJudgment(item)">-->
                                    <!--                                        <img :src="item.img" alt="" class="options-img-judgment" v-if="item.img && item.num!==0">-->
                                    <!--                                        <div class="image_box_none" v-else></div>-->
                                    <!--                                    </div>-->
                                    <VueDragResize
                                        v-if="DragResize"
                                        v-for="(item,index) in image_list51"
                                        :key="item.id"
                                        v-bind:ref="'imgDrag-' + index"
                                        :isActive="true"
                                        :isResizable="false"
                                        :isDraggable="true"
                                        :parentLimitation="true"
                                        :parentW="1920"
                                        :parentH="1080"
                                        :x="item.rights"
                                        :y="item.tops"
                                        :z="999"
                                        :w="333"
                                        :h="202"
                                        v-on:dragging="onDragging($event,index)"
                                        v-on:dragstop="onDragstop($event,index)"
                                    >
                                        <img :src="item.img" alt="" class="options-img-judgment" v-if="item.img && item.num!==0" style="z-index: 999">
                                    </VueDragResize>
                                </div>
                            </div>
                            <div class="options-judgment-box" style="">
                                <div class="options-judgment-box-answer" style="width: 90%;">
                                    <div v-for="(item,index) in image_list51_answer" :key="index" style="position: fixed;" :style="[{ 'top': item.top + 'px'},{'left':item.right+'px'}]">
                                        <img :src="item.img"  alt="" class="options-img-judgment" v-if="item.id && item.img">
                                        <div class="image_box" v-else>{{item.num}}</div>
                                    </div>
                                </div>
                            </div>
<!--                            <div class="option_button" style="position: fixed;bottom: 150px;right: 50px;">-->
<!--                                <div class="prev" @click="rearrange(64)">-->
<!--                                    <span>重新排列</span>-->
<!--                                </div>-->
<!--                                <div class="prev" @click="permutation">-->
<!--                                    <span>确定排列</span>-->
<!--                                </div>-->
<!--                            </div>-->
                        </template>
                        <!--                        木块图-->
                        <template v-if="topic.type === 63">
                            <div class="topic_53_box" style="width: 100%;height: 100%;touch-action: none" >
                                <div >
                                    <!--                                    <VueDragResize-->
                                    <!--                                        v-for="(item,index) in image_list53" :key="index"-->
                                    <!--                                        :isActive="true"-->
                                    <!--                                        :isResizable="false"-->
                                    <!--                                        :isDraggable="true"-->
                                    <!--                                        :parentLimitation="true"-->
                                    <!--                                        :parentW="1400"-->
                                    <!--                                        :parentH="460"-->
                                    <!--                                        :x="item.rights"-->
                                    <!--                                        :y="item.tops"-->
                                    <!--                                        :z="999"-->
                                    <!--                                        :w="0"-->
                                    <!--                                        :h="0"-->
                                    <!--                                        v-on:dragging="onDragging"-->
                                    <!--                                        v-on:dragstop="onDragstop($event,index)"-->
                                    <!--                                    >-->
                                    <!--                                        <img :src="item.img" alt="" class="options-img-judgment" style="z-index: 999">-->
                                    <!--                                    </VueDragResize>-->
                                    <div v-for="(item, index) in image_list53" :key="index"
                                         class="image-wrapper"
                                         :style="{transform: 'translate3d(' + item.rights + 'px, ' + item.tops+ 'px, 0) rotate(' + item.rotation + 'deg)'}"
                                         ref="img"
                                    >
                                        <img :src="item.img" alt="" class="drag-image" @contextmenu.prevent>
                                    </div>
                                </div>
                                <!--                                     :style="{'background-image':'url('+(this.topic.title_img)+')'}"-->
                                <img :src="topic.title_img" class="image_53_back" style="z-index: 100">
                                <!--                                <div v-for="(item,index) in topic.answer" :key="index">-->
                                <!--&lt;!&ndash;                                    左<input v-model="item.right" style="border: 1px solid #000000;"></input>&ndash;&gt;-->
                                <!--&lt;!&ndash;                                    上<input v-model="item.top" style="border: 1px solid #000000;"></input>&ndash;&gt;-->
                                <!--&lt;!&ndash;                                    <input v-model="item.id" style="border: 1px solid #000000;"></input>&ndash;&gt;-->
                                <!--&lt;!&ndash;                                    <input v-model="topic.id" style="border: 1px solid #000000;"></input>&ndash;&gt;-->
                                <!--&lt;!&ndash;                                    <button @click="tianjia(item)">添加 </button>&ndash;&gt;-->
                                <!--&lt;!&ndash;                                    <img :src="item.img" alt=""&ndash;&gt;-->
                                <!--&lt;!&ndash;                                         style="position: absolute;z-index: 111;opacity: 0.1"&ndash;&gt;-->
                                <!--&lt;!&ndash;                                         :style="[{ 'top': item.top + 'px'},{'left':item.right+'px'}]"&ndash;&gt;-->
                                <!--&lt;!&ndash;                                    >&ndash;&gt;-->
                                <!--                                </div>-->

<!--                                <div class="option_button">-->
<!--                                    <el-radio-group-->
<!--                                        v-if="topic.answer[0].child.length>0"-->
<!--                                        v-model="options"-->
<!--                                        @change="answer"-->
<!--                                        :class="(topic.answer[0].child || '').length > 4 ? 'radio-wrap' : ''"-->
<!--                                        id="radio" style="font-size: 20px">-->
<!--                                        <el-radio :label="item.id"-->
<!--                                                  v-for="item in topic.answer[0].child"-->
<!--                                                  :key="item.id"-->
<!--                                                  :class="(topic.answer[0].child || '').length > 4 ? 'radio' : ''"-->
<!--                                        >-->
<!--                                            {{ item.text }}-->
<!--                                        </el-radio>-->
<!--                                    </el-radio-group>-->
<!--                                    &lt;!&ndash;                                    <div class="prev" @click="rearrange(53)">&ndash;&gt;-->
<!--                                    &lt;!&ndash;                                        <span>重新排列</span>&ndash;&gt;-->
<!--                                    &lt;!&ndash;                                    </div>&ndash;&gt;-->

<!--                                    &lt;!&ndash;                                    <div class="prev" @click="permutation" v-if="topic.type === 58">&ndash;&gt;-->
<!--                                    &lt;!&ndash;                                        <span>确定排列</span>&ndash;&gt;-->
<!--                                    &lt;!&ndash;                                    </div>&ndash;&gt;-->
<!--                                </div>-->
                            </div>
                        </template>
                        <!--                        图形拼凑-->
                        <template v-if="topic.type === 65">
                            <div class="topic_53_box" style="width: 100%;height: 100%;touch-action: none">
                                <!--                                <div style="touch-action: none">-->
                                <!--                                    <img-->
                                <!--                                        v-for="(image, index) in image_list53"-->
                                <!--                                        :key="index"-->
                                <!--                                        style="display: block; transform-origin: center center;will-change: transform;transform: none;position: fixed;"-->
                                <!--                                        ref="img"-->
                                <!--                                        :src="image.img"-->
                                <!--                                        :style="{ transform: `rotate(${image.rotation}deg)`, left: `${image.rights}px`, top: `${image.tops}px` }"-->
                                <!--                                        @touchstart="onTouchStart(index, $event)"-->
                                <!--                                        @touchmove="onTouchMove(index, $event)"-->
                                <!--                                        @contextmenu.prevent-->
                                <!--                                    />-->
                                <!--                                </div>-->
                                <div v-for="(item, index) in image_list53" :key="index"
                                     class="image-wrapper"
                                     :style="{transform: 'translate3d(' + item.rights + 'px, ' + item.tops+ 'px, 0) rotate(' + item.rotation + 'deg)'}"
                                     ref="img"
                                >
                                    <img :src="item.img" alt="" class="drag-image" @contextmenu.prevent>
                                </div>
                                <!--                                     :style="{'background-image':'url('+(this.topic.title_img)+')'}"-->
                                <div>
                                    <img :src="topic.title_img"
                                         ref="hammerTest"
                                         class="image_53_back"
                                         style="z-index: 100;right: 450px;display: block;"
                                    >
                                </div>
                                <!--                                <div v-for="(item,index) in topic.answer" :key="index">-->
                                <!--                                    &lt;!&ndash;                                    左<input v-model="item.right" style="border: 1px solid #000000;"></input>&ndash;&gt;-->
                                <!--                                    &lt;!&ndash;                                    上<input v-model="item.top" style="border: 1px solid #000000;"></input>&ndash;&gt;-->
                                <!--                                    &lt;!&ndash;                                    <input v-model="item.id" style="border: 1px solid #000000;"></input>&ndash;&gt;-->
                                <!--                                    &lt;!&ndash;                                    <input v-model="topic.id" style="border: 1px solid #000000;"></input>&ndash;&gt;-->
                                <!--                                    &lt;!&ndash;                                    <button @click="tianjia(item)">添加 </button>&ndash;&gt;-->
                                <!--                                    &lt;!&ndash;                                    <img :src="item.img" alt=""&ndash;&gt;-->
                                <!--                                    &lt;!&ndash;                                         style="position: absolute;z-index: 111;opacity: 0.1"&ndash;&gt;-->
                                <!--                                    &lt;!&ndash;                                         :style="[{ 'top': item.top + 'px'},{'left':item.right+'px'}]"&ndash;&gt;-->
                                <!--                                    &lt;!&ndash;                                    >&ndash;&gt;-->
                                <!--                                </div>-->
                                <div class="type58Box">
                                    <div>
                                        <el-radio-group v-model="type58id"
                                                        @change="answer58"
                                                        :class="(answerList58 || '').length > 4 ? 'radio-wrap' : ''"
                                                        id="radio" style="font-size: 20px"
                                        >
                                            <el-radio :label="item.id"
                                                      v-for="item in answerList58"
                                                      :key="item.id"
                                                      :class="(answerList58 || '').length > 4 ? 'radio' : ''"
                                            >
                                                {{ item.text }}
                                            </el-radio>
                                        </el-radio-group>
                                        <el-radio-group
                                            v-if="type58id == 2"
                                            v-model="score58id"
                                            @change="score58answer"
                                            :class="(scoreList || '').length > 4 ? 'radio-wrap' : ''"
                                            id="radio" style="font-size: 20px"
                                        >
                                            <el-radio :label="item.id"
                                                      v-for="item in scoreList"
                                                      :key="item.id"
                                                      :class="(scoreList || '').length > 4 ? 'radio' : ''"
                                            >
                                                {{ item.text }}
                                            </el-radio>
                                        </el-radio-group>
                                    </div>
                                </div>
                                <div class="option_button">
                                    <!--                                    <div class="prev" @click="rearrange(53)">-->
                                    <!--                                        <span>重新排列</span>-->
                                    <!--                                    </div>-->

                                    <!--                                    <div class="prev" @click="permutation" v-if="topic.type === 58">-->
                                    <!--                                        <span>确定排列</span>-->
                                    <!--                                    </div>-->
                                </div>
                            </div>
                        </template>
<!--                        翻方块-->
                        <template v-if="topic.type === 52">
                            <div class="flex">
                                <div class="options52_box">
                                    <div v-for="(item,index) in image_list52" :key="index"
                                         style="cursor: pointer;position: relative;" @click="answerJudgment(item.num)" class="options52_box_child"
                                         :style="{'background': item.color}" >
                                    </div>
                                </div>
                                <div style="width: 400px;margin-left: 150px;margin-top: 200px;">
                                    <el-radio-group v-model="options"
                                                    @change="answer"
                                                    :class="(topic.answer || '').length > 4 ? 'radio-wrap' : ''"
                                                    id="radio" style="font-size: 20px">
                                        <el-radio :label="item.id"
                                                  v-for="item in topic.answer"
                                                  :key="item.id"
                                                  :class="(topic.answer || '').length > 4 ? 'radio' : ''"
                                        >
                                            {{ item.text }}
                                        </el-radio>
                                    </el-radio-group>
                                </div>
                            </div>
                        </template>
<!--                        编码测验-->
                        <template v-if="topic.type === 62">
                            <div style="">
                                <img :src="topic.title_img" alt="" class="options-img-judgment" style="width: 604px;height: 102px;margin: 0 auto;display: block;">
                            </div>
                            <div class="flex flex-justify-content-center" style="margin-top: 40px;font-size: 0!important;padding-left: 42px;position: relative">
                                <div v-for="(item,index) in topic.answer" :key="index" >
                                    <img :src="item.img" alt="" class="options-img-judgment" style="width: 49.52px;height: 49.52px;margin-right: 42px;">
                                    <div v-for="(item1,index1) in item.child" :key="index1" style="cursor: pointer;position: relative;" @click="typeClick(index,index1)">
                                        <img v-if="item1.show" :src="item1.mp3" alt="" class="options-img-judgment" style="width: 49.52px;height: 49.52px;margin-right: 42px;">
                                        <img v-else="item1.show" :src="item1.img" alt="" class="options-img-judgment" style="width: 49.52px;height: 49.52px;margin-right: 42px;">
                                    </div>
                                </div>
                            </div>
<!--                            <div class="submitButton" @click="submit054">-->
<!--                                <span>确定</span>-->
<!--                            </div>-->
                        </template>
                    </div>
                    <img :src="topic.image" alt="" class="options-img" v-if="topic.image">
                </div>
                <div class="flex flex-justify-content-center button-wrap">
                    <div class="flex">
                        <div class="prev prev1" @click="readTopicRepeat">
                            <img src="../assets/images/language.png" alt="">
                            <span>重复语言</span>
                        </div>
<!--                        <div class="prev prev2" style="margin-left: 70px;" @click="prevTopic('prev')">-->
<!--                            <img src="../assets/images/prev.png" alt="">-->
<!--                            <span>上一题</span>-->
<!--                        </div>-->
<!--                        <div class="prev prev3" style="margin-left: 70px;" @click="nextTopic('next')">-->
<!--                            <span>下一题</span>-->
<!--                            <img src="../assets/images/next.png" alt="">-->
<!--                        </div>-->
                        <!--                    <el-button class="skip-question" @click="nextTopic">-->
                        <!--                        <span>跳过</span>-->
                        <!--                        <img src="../assets/images/skip.png" alt="">-->
                        <!--                    </el-button>-->
                        <div class="prev prev4" style="margin-left: 70px;" @click="endExam">
                            <span>结 束</span>
                            <img src="../assets/images/submit.png" alt="">
                        </div>
                        <div class="prev prev4" style="margin-left: 70px;" @click="quitExam">
                            <span>退 出</span>
                            <img src="../assets/images/submit.png" alt="">
                        </div>
                        <div class="prev prev2" style="margin-left: 70px;" @click="rearrange(64)" v-if="topic.type === 64">
                            <span>重新排列</span>
                        </div>
                        <div class="prev prev2" style="margin-left: 70px;" @click="permutation" v-if="topic.type === 64">
                            <span>确定排列</span>
                        </div>
                        <el-radio-group
                            v-if="topic.answer && topic.answer[0]  && topic.answer[0].child && topic.answer[0].child.length>0 && topic.type === 63"
                            v-model="options"
                            @change="answer"
                            :class="(topic.answer[0].child || '').length > 4 ? 'radio-wrap' : ''"
                            id="radio" style="font-size: 20px">
                            <el-radio :label="item.id"
                                      v-for="item in topic.answer[0].child"
                                      :key="item.id"
                                      :class="(topic.answer[0].child || '').length > 4 ? 'radio' : ''"
                            >
                                {{ item.text }}
                            </el-radio>
                        </el-radio-group>
                        <!--                        <div class="prev prev2" style="margin-left: 70px;" @click="submit054" v-if="topic.type === 62">-->
                        <!--                            <span>确定</span>-->
                        <!--                        </div>-->
                        <!--                    <div class="prev prev5" style="margin-left: 70px;"  @click="createOtherWin">-->
                        <!--                        <span>副屏作答</span>-->
                        <!--                    </div>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script src="https://cdn.bootcdn.net/ajax/libs/hammer.js/2.0.8/hammer.min.js">
</script>
<script>
import $ from 'jquery'
//import { defineComponent, reactive, onMounted, onUnmounted, getCurrentInstance } from 'vue'

//const global = require("/src/plugs/ipcRenderer");
/*const state = reactive({
    open: true,
    message: ''
})*/
import VueDragResize from "vue-drag-resize";
import Vue from "vue"
// const Hammer = require('hammerjs');
// import Hammer from "hammerjs";
export default {
    name: "TerminalAnswer",
    components: {
        VueDragResize
    },
    data() {
        return {
            state:{
                open:true,
                message: ''
            },
            options: '',
            topic: {},
            titleVoice: '',
            answerVoice: '',
            gap_filling: [],
            gap: '',
            hour: '',
            minute: '',
            second: '',
            promiseTimer: '',
            time_show: false,
            limit_Time: '',
            music_time: '',
            answerList:[],
            options_50:'',
            image_list51:[],
            image_list51_answer:[],
            num:0,
            image_list52:[],
            image_list53:[],
            // image_list53_answer:[],
            // right:'',
            // top:'',
            zhidaoyuDjs:false,
            // timerzdy:null,
            answerList58:[
                {
                    text:'正确',
                    id:1
                },
                {
                    text:'错误',
                    id:2
                }
            ],
            type58id:'',
            scoreList:[
                {
                    id:0,
                    text:'A.错误'
                },
                {
                    id:1,
                    text:'B.一个节点'
                },
                {
                    id:2,
                    text:'C.两个节点'
                },
                {
                    id:3,
                    text:'D.三个节点'
                },
                {
                    id:4,
                    text:'E.四个节点'
                },
                {
                    id:5,
                    text:'F.五个节点'
                },
                {
                    id:6,
                    text:'G.六个节点'
                },
                {
                    id:7,
                    text:'H.七个节点'
                },
                {
                    id:8,
                    text:'I.八个节点'
                },
                {
                    id:9,
                    text:'J.九个节点'
                },
            ],
            score58id:'',
            zhidaoyuVoice:'',
            rotate: 0,
            transform:'',
            permutationNum:0,
            websock: null,
            token:'',
            sendData:'',
            mcList: [], // 存储Hammer.js管理器的数组
            startRotation:0,
            DragResize:true,
            isQuestion:true,
            timerWeb:null,
            screenQ_id:''
        };
    },
    props: {
        // 倒计时间总秒数
        // remainTime: {
        //     default: '1800'
        // }
    },
    computed: {
        hourString() {
            return this.formatNum(this.hour)
        },
        minuteString() {
            return this.formatNum(this.minute)
        },
        secondString() {
            return this.formatNum(this.second)
        },
    },

    watch: {},
    beforeDestroy() {
        this.mcList.forEach((mc) => {
            mc.destroy();
        });
    },

    async mounted() {
        // this.init()
        // console.log('token',this.$store.state.user.token)
        this.token = this.$store.state.user.token
        await this.getTopic();
        // if(!this.zhidaoyuDjs) {
        //     this.readTopic();
        // }
        // if (this.remainTime > 0) {
        //     this.hour = Math.floor((this.remainTime / 3600) % 24)
        //     this.minute = Math.floor((this.remainTime / 60) % 60)
        //     this.second = Math.floor(this.remainTime % 60)
        //     this.countDowm()
        // }

    },
    methods: {
        init() {
            let url = 'ws://59.110.67.66:8302'
            console.log('主屏url',url)
            this.websock = new WebSocket(url)
            // 监听打开
            this.websock.onopen= this.websockOpen;
            // 监听关闭
            this.websock.onclose = this.websockClose;
            //监听异常
            this.websock.onerror = this.websockError;
            //监听服务器发送的消息
            this.websock.onmessage = this.websockMessage;
            console.log('this.websock主屏',this.websock)
        },
        //连接建立之后执行send方法发送数据
        websockOpen() {
            console.log('监听打开（主屏）')
            this.websocketsend('1001'+this.token+','+this.$store.state.user.hukou);
            this.websockTimer()

            // this.websocketsend(this.$store.state.token);
        },
        //实时发送
        websockTimer() {
            this.timerWeb = setInterval(() =>{
                this.websocketsend('heartbeat1');
            },10000)
        },
        //清除实时发送
        clearTimerWeb() {
            clearInterval(this.timerWeb)
            this.timerWeb = null
        },
        //关闭
        websockClose() {
            console.log('主屏监听关闭')
            // 关闭当前连接
            this.websock.close();
            // 重新连接
            setTimeout(() => {
                this.init()
            }, 3000); // 3秒延迟后重新连接
        },
        //连接建立失败重连
        websockError() {
            console.log('主屏监听异常')
            // 关闭当前连接
            this.websock.close();
            // 重新连接
            setTimeout(() => {
                this.init()
            }, 3000); // 3秒延迟后重新连接
        },
        //数据接收
        websockMessage(e) {
            console.log('监听服务器接收的消息（主屏）',e.data)
            let a = e.data.substr(0,4)
            if(a == 'huan') {
                this.sendData =  e.data.slice(4)
                let data = {}
                data.exam_id = this.$route.query.exam_id
                data.patient_id = this.$route.query.patient_id
                this.websocketsend('1000'+this.sendData+'@'+'examid'+JSON.stringify(data));
            }
            if(a == 'yuyu') {
                let result = e.data.slice(4)
                if (result.length == 10) {
                    //选择题选中   确定按钮点击
                    // if(result == 'answer_end') {
                    //     this.answer_end()
                    // }
                    // if(result == 'prev_topic') {
                    //     this.prevTopic()
                    // }
                    // if(result == 'next_topic') {
                    //     this.nextTopic()
                    // }
                } else {
                    let identification = result.substr(0,6)
                    // console.log('主屏identification',identification)
                    /**副屏通知已经连上*/
                    if (identification == 'online') {
                        let data = {}
                        data.exam_id = this.$route.query.exam_id
                        data.patient_id = this.$route.query.patient_id
                        this.websocketsend('1000'+this.sendData+'@'+'examid'+JSON.stringify(data));
                    }
                    //编码题型
                    if (identification == 'bianma') {
                        // console.log('主屏bianma')
                        let content = result.slice(6)
                        this.topic.answer = JSON.parse(content)
                        this.$forceUpdate()
                    }
                    if (identification == 'pincou') {
                        this.$nextTick(() => {
                            let content = result.slice(6)
                            this.image_list51 = []
                            this.image_list51_answer = []
                            this.image_list51 = JSON.parse(content).image_list51
                            this.image_list51_answer = JSON.parse(content).image_list51_answer
                            // console.log('this.image_list51',this.image_list51)
                            // console.log('this.image_list51_answer',this.image_list51_answer)
                            this.$forceUpdate()
                        })
                    }
                    // if (identification == 'pincoe') {
                    //     this.$nextTick(() => {
                    //         console.log('主屏JSON.parse(content)',JSON.parse(content))
                    //         this.image_list51 = []
                    //         this.image_list51 = JSON.parse(content).image_list51
                    //         // this.image_list51_answer = JSON.parse(content).image_list51_answer
                    //         this.$forceUpdate()
                    //     })
                    // }
                    if (identification == 'colors') {
                        let content = result.slice(6)
                        this.image_list52 = JSON.parse(content)
                        this.$forceUpdate()
                        // console.log('主屏this.image_list52',this.image_list52)
                    }
                    if (identification == 'mukuai') {
                        this.$nextTick(() => {
                            let content = result.slice(6)
                            this.image_list53 = JSON.parse(content)
                            this.$forceUpdate()
                        })
                    }
                    if (identification == 'examjs') {
                        this.zhidaoyuDjs = false
                        this.answer_page()
                    }
                    if (identification == 'pingtu') {
                        this.$nextTick(() => {
                            let content = result.slice(6)
                            this.image_list53 = JSON.parse(content)
                            this.$forceUpdate()
                        })
                    }
                    if (identification == 'isQues') {
                        let content = result.slice(6)
                        let id = JSON.parse(content).id
                        console.log('11111111isQues',id,this.topic.id)
                        if(id == this.topic.id) {
                            this.isQuestion = true
                            this.screenQ_id = id
                        }
                    }
                    if (identification == 'naxtTi') {
                        let content = result.slice(6)
                        let id = JSON.parse(content).id
                        if(id == this.topic.id) {
                            this.nextTopic('communication')
                        } else {
                            this.topic.id = id
                            this.nextTopic('communication')
                        }
                    }
                    if (identification == 'jieshu') {
                        window.speechSynthesis.cancel()
                        this.$router.push({
                            name: 'TerminalEnd',
                            query: {
                                exam_id: this.exam_id,
                                patient_id:this.patient_id,
                            }
                        })
                        this.clearTimerWeb()
                    }
                    if (identification == 'tongBu') {
                        let content = result.slice(6)
                        let id = JSON.parse(content).id
                        this.topic.id = id
                        this.presentInterface()
                        let answerJson = {
                            id:this.topic.id,
                        }
                        this.websocketsend('1000'+this.sendData+'@'+'isQues'+JSON.stringify(answerJson))
                    }
                }
                // console.log('主屏result',result)
            }
        },
        //数据发送
        websocketsend(Data){
            console.log('Data（主屏）',Data)
            this.websock.send(Data);
        },
        // onTouchStart(index, event) {
        //     // console.log('event',event)
        //     // 阻止事件冒泡
        //     event.stopPropagation();
        // },
        // onTouchMove(index, event) {
        //     // 阻止事件冒泡
        //     event.stopPropagation();
        // },
        // onTouchEnd(index) {
        //     // 处理手势结束事件
        // },
        /**
         * 图片旋转事件
         * */
        rotateChange() {
            function throttle(fn, delay) {
                let last = 0;
                return function (...args) {
                    const now = +new Date();
                    if (now - last >= delay) {
                        fn.apply(this, args);
                        last = now;
                    }
                };
            }
            const images = this.$refs.img;
            this.$nextTick(() => {
                const mcList = [];
                images.forEach((image) => {
                    var mc = new Hammer.Manager(image, {
                        recognizers: [
                            [Hammer.Pan, { direction: Hammer.DIRECTION_ALL }],
                            [Hammer.Rotate, { threshold: 0.1 }],  // 设置旋转角度的最小阈值
                        ],
                        touchAction: 'auto', // 允许页面元素的默认行为
                    });
                    mc.on('panstart', (event) => this.onPan(event));
                    // mc.on('panmove', (event) => this.onPan(event));
                    mc.on('panmove',throttle((event) => this.onPan(event),16))
                    mc.on('panend', (event) => this.onPan(event));
                    mc.on('rotatestart', (event) => this.onRotate(event));
                    mc.on('rotatemove',throttle((event) => this.onRotate(event),16))
                    // mc.on('rotatemove', (event) => this.onRotate(event));
                    mc.on('rotateend',throttle((event) => {
                        const index = [].indexOf.call(this.$refs.img, event.target.parentNode);
                        if (index < 0 || event.type !== 'rotateend') {
                            return;
                        }
                        this.image_list53[index].startAngle = this.image_list53[index].rotation;
                    },16))

                    // mc.on('rotateend', (event) => {
                    //     const index = [].indexOf.call(this.$refs.img, event.target.parentNode);
                    //     if (index < 0 || event.type !== 'rotateend') {
                    //         return;
                    //     }
                    //     this.image_list53[index].startAngle = this.image_list53[index].rotation;
                    // });
                    mcList.push(mc);
                });
                this.mcList = mcList;
            });
        },
        onPan(event) {
            const element = event.target.parentNode;
            const index = [].indexOf.call(this.$refs.img, element);
            if (index < 0) {
                return;
            }
            const item = this.image_list53[index];
            if(event.type === 'panstart') {
                element.style.zIndex = 1;
                item.isDragging = true;
                item.startX = item.rights;
                item.startY = item.tops;
            } else if (item.isDragging && event.type === 'panmove') {
                const deltaX = event.deltaX;
                const deltaY = event.deltaY;
                this.image_list53[index].rights = item.startX + deltaX;
                this.image_list53[index].tops = item.startY + deltaY;
                element.style.transform = `translate3d(${this.image_list53[index].rights}px, ${this.image_list53[index].tops}px, 0) rotate(${this.image_list53[index].rotation}deg)`;
            } else if (item.isDragging && event.type === 'panend') {
                this.image_list53[index].isDragging = false;
                element.style.zIndex = "";
            }
        },
        onRotate(event) {
            const element = event.target.parentNode;
            const index = [].indexOf.call(this.$refs.img, element);
            if (index < 0) {
                return;
            }
            if (event.type === 'rotatestart') {
                this.image_list53[index].startAngle = this.image_list53[index].rotation;
                this.image_list53[index].startRotation = event.rotation;
                const pinch = this.mcList[index].get('pinch');
                if (pinch) {
                    this.mcList[index].recognizeWith(pinch, false);
                }
                if (event.pointers.length !== 2) {
                    this.mcList[index].get('rotate').set({ enable: false });
                    this.mcList[index].get('rotate').set({ enable: true });
                    return;
                }
                this.startRotation = event.rotation;
                event.preventDefault();
            } else if (event.type === 'rotatemove') {
                if (event.pointers.length === 2  && Math.abs(event.rotation - this.image_list53[index].startRotation) > 10) {
                    if (Math.abs(event.deltaX) > 10 || Math.abs(event.deltaY) > 10) {
                        const rotation = Number(this.image_list53[index].startAngle) + Number(event.rotation - this.image_list53[index].startRotation);
                        this.image_list53[index].rotation = rotation % 360;;
                        if (this.image_list53[index].rotation < 0) {
                            this.image_list53[index].rotation += 360;
                        }
                        requestAnimationFrame(() => {// 优化动画性能
                            element.style.transform = `translate3d(${this.image_list53[index].rights}px, ${this.image_list53[index].tops}px, 0) rotate(${this.image_list53[index].rotation}deg)`;
                        });                    }
                }
            }
        },

        // rotateChange() {
        //     let that = this;
        //     const images = that.$refs.img; // 获取需要手势操作的元素
        //     if (!images) {
        //         return;
        //     }
        //     for (let i = 0; i < images.length; i++) {
        //         const element = images[i];
        //         let posX = 0;
        //         let posY = 0;
        //         let rotation = 0;
        //         let lastPosX = 0;
        //         let lastPosY = 0;
        //         let lastRotation = 0;
        //         let fingerCount = 0;
        //         const hammer = new Hammer(element, {
        //             touchAction: 'auto',
        //         });
        //         const pan = new Hammer.Pan({
        //             direction: Hammer.DIRECTION_ALL,
        //             threshold: 0,
        //             pointers: 0,
        //             enableInertia: false,
        //         });
        //         const rotate = new Hammer.Rotate({
        //             threshold: 0,
        //         });
        //         const pinch = new Hammer.Pinch({
        //             enable: true,
        //         });
        //         hammer.add(pan);
        //         hammer.add(rotate);
        //         hammer.add(pinch);
        //         // 禁用 tap、doubletap、press 和 swipe 手势
        //         ['tap', 'doubletap', 'press', 'swipe'].forEach(name => {
        //             hammer.get(name).set({ enable: false });
        //         });
        //         // 禁用缩放手势和旋转手势之间的默认关联
        //         pinch.recognizeWith(rotate);
        //         hammer.on('panstart', function(e) {
        //             lastPosX = posX || e.deltaX;//使用当前位置或者开始移动时的位置作为初始位置
        //             lastPosY = posY || e.deltaY;
        //         });
        //         hammer.on(
        //             'panmove',
        //             _.throttle(function(e) {
        //                 posX = lastPosX + e.deltaX;
        //                 posY = lastPosY + e.deltaY;
        //                 updateElement();
        //                 // that.image_list53[i-1].transform =  `translate(${posX}px,${posY}px) rotate(${rotation}deg)`
        //             }, 50)
        //         ); // 使用节流
        //         hammer.on('rotatestart', function(e) {
        //             if (e.pointerType !== 'mouse') {
        //                 fingerCount = e.pointers.length;
        //                 if (fingerCount === 2) {
        //                     lastRotation = parseInt(
        //                         element.style.transform.match(/rotate\((.*?)deg\)/)[1]
        //                     );
        //                 }
        //             }
        //         });
        //         hammer.on('rotatemove', function(e) {
        //             if (e.pointerType !== 'mouse') { // 判断是否是鼠标事件
        //                 fingerCount = e.pointers.length;
        //                 if (fingerCount === 2) {
        //                     e.preventDefault();
        //                     let angle = e.rotation - lastRotation;
        //                     lastRotation = e.rotation;
        //                     rotation = (rotation + angle) % 360;
        //                     updateElement();
        //                 }
        //             }
        //         });
        //         hammer.on('rotateend', function(e) {
        //             if (e.pointerType !== 'mouse') { // 判断是否是鼠标事件
        //                 fingerCount = e.pointers.length;
        //                 if (fingerCount < 2) {
        //                     lastRotation = 0;
        //                     hammer.get('rotate').set({ threshold: 10 }); // 重新设置旋转手势的阈值
        //                     // 使用 requestAnimationFrame 将更新操作放到下一帧中执行
        //                     requestAnimationFrame(function() {
        //                         // 遍历当前图片列表，找到与当前元素对应的图片对象
        //                         const image = that.image_list53.find(item => item.img === element.src.split('/').pop());
        //                         if (image) {
        //                             // 更新图片对象的旋转角度
        //                             image.rotation = rotation;
        //                         }
        //                         // 强制重置 transform 属性
        //                         element.style.transform = `translate(${posX}px, ${posY}px) rotate(${rotation}deg)`;
        //                     });
        //                 }
        //             }
        //         });
        //         // 更新图片的位置和旋转角度
        //         function updateElement() {
        //             element.style.transform = `translate(${posX}px, ${posY}px) rotate(${rotation}deg)`;
        //         }
        //         // 在每一帧进行图片的旋转
        //         function update() {
        //             updateElement();
        //             requestAnimationFrame(update);
        //         }
        //         update();
        //     }
        // },
        /**
         * 指导语关闭
         * */
        exam_end() {
            this.zhidaoyuDjs = false
            // this.timerzdy = null
            this.answer_page()
            this.websocketsend('1000'+this.sendData+'@'+'examjs');

            // this.readTopic()
            // if(this.topic.type == 65) {
            //     this.$nextTick(() => {
            //         // this.image_list53.map((item,index) => {
            //         //     this.rotateChange(index)
            //         // })
            //         this.rotateChange()
            //     })
            // }
            // clearInterval(this.timerzdy)
        },
        /**
         * 编码确定按钮
         * */
        submit054() {
            if(!this.isQuestion) {
                this.$message.error('当前主副屏未同步，请稍后进行作答!')
                let answerJson = {
                    id:this.topic.id,
                }
                this.websocketsend('1000'+this.sendData+'@'+'tongBu'+JSON.stringify(answerJson))
                return
            }
            let answer_id = []
            let answer_idNum = []
            for (let a = 0; a < this.topic.answer.length; a++) {
                this.topic.answer[a].child.map((item, index) => {
                    if (item.show) {
                        answer_id[a] = item.id
                        answer_idNum.push(item.id)
                    }
                })
            }
            if (answer_idNum.length < this.topic.answer.length) {
                this.$message.error('请选择对应的符号')
                return
            }
            // console.log('answer_id',answer_id)
            // this.topic.answer.map((item,index) => {
            //     item.child.map((a,b) => {
            //         if(a.show == true) {
            //             answer_id.push(a.id)
            //         }
            //     })
            // })
            let params = {
                exam_id: this.$route.query.exam_id,
                patient_id:this.$route.query.patient_id,
                question_id: this.topic.id,
                answer_id: String(answer_id)
            }
            this.$axios.post('api/TrainExam/aswer', this.$qs.stringify(params)).then(res => {
                if (res.data.code === 1) {
                    this.answer_end()
                    // this.clearTimerWeb()
                    // this.websocketsend('1000'+this.sendData+'@'+'answer_end');
                    // setTimeout(() => {
                    //     this.websockTimer()
                    // },1000)
                } else {
                    this.$message.error(res.data.info)
                }
            })
        },
        /**
         * 编码题型选中
         * */
        typeClick(index,index1) {
            if(this.topic.answer[index].child[index1].show) {
                this.topic.answer[index].child[index1].show = false
            } else {
                this.topic.answer[index].child.map((a,b) => {
                    a.show = false
                })
                this.topic.answer[index].child[index1].show = true
            }
            this.websocketsend('1000'+this.sendData+'@'+'bianma'+JSON.stringify(this.topic.answer));

            // console.log('this.topic.answer[index].child[index1]',this.topic.answer[index].child[index1])
            this.$forceUpdate()
        },
        // tianjia(item) {
        //     let params = {
        //         aid: item.id,
        //         right:item.right,
        //         qid: this.topic.id,
        //         top: item.top,
        //     }
        //     this.$axios.post('api/TrainExam/add_move', this.$qs.stringify(params)).then(res => {
        //
        //     })
        // },
        /**
         * 拖拽结束事件
         * */
        onDragstop(newRect,option) {
            event.preventDefault();
            const THRESHOLD = 50; // 定义一个常量，存储数字30
            var that = this;
            if(that.topic.type == 64) {
                // let isshow = ''
                function updateImageListItem(option, item, newRect) {
                    that.DragResize = false
                    const optionData = that.image_list51[option];
                    if (newRect.left < item.right + THRESHOLD &&
                        newRect.left > item.right - THRESHOLD &&
                        newRect.top < item.top + THRESHOLD &&
                        newRect.top > item.top - THRESHOLD &&
                        !item.id
                    ) {
                        // console.log('1111111111')
                        // item.img = that.image_list51[option].img
                        // item.id = that.image_list51[option].id
                        // that.image_list51[option].num = 0
                        // that.image_list51[option].rights = newRect.left
                        // that.image_list51[option].tops = newRect.top
                        Vue.set(item, 'img', that.image_list51[option].img);
                        Vue.set(item, 'id', that.image_list51[option].id);
                        Vue.set(optionData, 'num', 0);
                        Vue.set(that.image_list51[option], 'rights', newRect.left);
                        Vue.set(that.image_list51[option], 'tops', newRect.top);
                        let answerJson = {
                            image_list51:that.image_list51,
                            image_list51_answer:that.image_list51_answer
                        }
                        that.websocketsend('1000'+that.sendData+'@'+'pincou'+JSON.stringify(answerJson));

                    } else {
                        that.$nextTick(() => {
                            Vue.set(that.image_list51[option], 'rights', that.image_list51[option].right);
                            Vue.set(that.image_list51[option], 'tops', that.image_list51[option].top);
                            that.DragResize = true;
                            // that.image_list51.forEach((item1,index1) => {
                            //     if(index1 == option) {
                            //         item1.rights = item1.right
                            //         item1.tops = item1.top
                            //     }
                            // })
                            // that.image_list51=JSON.parse(JSON.stringify(that.image_list51));
                            // that.image_list51[option].rights = that.image_list51[option].right
                            // that.image_list51[option].tops = that.image_list51[option].top
                            // console.log(11, that.image_list51[option].rights, that.image_list51[option].right, that.image_list51[option].tops, that.image_list51[option].top)
                        })
                    }
                    // that.DragResize = true
                }
                that.image_list51_answer.forEach((item, index) => {
                    updateImageListItem(option, item, newRect)
                })
                // let answerJson = {
                //     image_list51:that.image_list51,
                //     image_list51_answer:that.image_list51_answer
                // }
                // that.websocketsend('1000'+that.sendData+'@'+'pincou'+JSON.stringify(answerJson));
            }
            // else if (that.topic.type == 53) {
            //     that.$nextTick(() => {
            //         that.image_list53[option].rights = newRect.left
            //         that.image_list53[option].tops = newRect.top
            //         that.websocketsend('1000'+that.sendData+'@'+'mukuai'+JSON.stringify(that.image_list53));
            //     })
            //
            // }
            // let a = ''
            // if(that.image_list53[option].rights < that.image_list53[option].right+60 && that.image_list53[option].rights > that.image_list53[option].right-60
            //     && that.image_list53[option].tops < that.image_list53[option].top+60 && that.image_list53[option].tops > that.image_list53[option].top-60) {
            //     that.$nextTick(() => {
            //         that.image_list53[option].rights = Number(that.image_list53[option].right)
            //         that.image_list53[option].tops = Number(that.image_list53[option].top)
            //         // if(that.topic.type == 65) {
            //         //     console.log('that.image_list53_answer',typeof(that.image_list53_answer))
            //         //     if (!that.image_list53_answer.includes(that.image_list53[option].id)) {
            //         //         that.image_list53_answer.push(that.image_list53[option].id);
            //         //     }
            //         // }
            //     })
            // } else {
            //     // if(that.topic.type == 65) {
            //     //     if(that.image_list53_answer .length>0) {
            //     //         that.image_list53_answer = that.image_list53_answer.filter(item => item != that.image_list53[option].id)
            //     //     }
            //     //
            //     // }
            //
            // }
        },
        /**
         * 拖拽时事件
         * */
        onDragging(newRect,option) {
            event.preventDefault();
            // console.log(newRect.left)
            let that = this
            if(that.topic.type == 64) {
                that.$nextTick(() => {
                    // console.log('that.permutationNum',that.permutationNum)
                    if (that.permutationNum === 1) {
                        that.isDragging = true;
                        that.image_list51[option].rights = that.image_list51[option].right
                        that.image_list51[option].tops = that.image_list51[option].top
                        that.isDragging = false;
                        return;
                    }
                    that.isDragging = true;
                    that.image_list51[option].rights = newRect.left
                    that.image_list51[option].tops = newRect.top
                    that.isDragging = false;
                    // that.$refs.imgDrag[option].left = newRect.left
                    // that.$refs.imgDrag[option].top = newRect.top
                })
                // let answerJson = {
                //     image_list51:that.image_list51,
                //     // image_list51_answer:that.image_list51_answer
                // }
                // that.websocketsend('1000'+that.sendData+'@'+'pincoe'+JSON.stringify(answerJson));
            }
        },
        /**
         * 确定排列
         * */
        permutation() {
            if(!this.isQuestion) {
                this.$message.error('当前主副屏未同步，请稍后进行作答!')
                let answerJson = {
                    id:this.topic.id,
                }
                this.websocketsend('1000'+this.sendData+'@'+'tongBu'+JSON.stringify(answerJson))
                return
            }
            if(this.topic.type == 64) {
                let answer_id = []
                // let a = []
                this.image_list51_answer.map((item,index) => {
                    // if(item.id) {
                    //     a.push(item.id)
                    // }
                    answer_id.push(item.id)
                })
                //
                // console.log('answer_id',answer_id,this.image_list51_answer)
                // if(a.length<=0) {
                //     this.$message.error('请排列顺序')
                //     return
                // }
                let params = {
                    exam_id: this.$route.query.exam_id,
                    patient_id:this.$route.query.patient_id,
                    question_id: this.topic.id,
                    answer_id: answer_id,
                    score: answer_id.length > 0 ? '' : 0
                }
                this.$axios.post('api/TrainExam/aswer', this.$qs.stringify(params)).then(res => {
                    if (res.data.code === 1) {
                        this.answer_end()
                        // this.clearTimerWeb()
                        // this.websocketsend('1000'+this.sendData+'@'+'answer_end');
                        // setTimeout(() => {
                        //     this.websockTimer()
                        // },1000)

                    } else {
                        this.$message.error(res.data.info)
                    }
                })
            } else if (this.topic.type == 65) {
                // console.log('this.image_list53_answer',this.image_list53_answer)
                // let answer_id = []
                // this.image_list53_answer.map((item,index) => {
                //     answer_id.push(item)
                // })
                // let params = {
                //     exam_id: this.$route.query.exam_id,
                //     patient_id:this.$route.query.patient_id,
                //     question_id: this.topic.id,
                //     answer_id: answer_id,
                //     image_list52:this.image_list53
                // }
                // this.$axios.post('api/TrainExam/aswer', this.$qs.stringify(params)).then(res => {
                //     if (res.data.code === 1) {
                //         this.answer_end()
                //     } else {
                //         this.$message.error(res.data.info)
                //     }
                // })
            }
        },
        /**
         * type51  图片初始位置
         * */
        image51Wei() {
            let that = this
            if(that.image_list51.length == 3) {
                // console.log('1111',11111111)
                that.image_list51[0].rights = 230
                that.image_list51[1].rights = 710
                that.image_list51[2].rights = 1190
                that.image_list51[0].right = 230
                that.image_list51[1].right = 710
                that.image_list51[2].right = 1190
                that.image_list51_answer[0].right = 230
                that.image_list51_answer[1].right = 710
                that.image_list51_answer[2].right = 1190
            } else if (that.image_list51.length == 4) {
                that.image_list51[0].rights = 130
                that.image_list51[1].rights = 510
                that.image_list51[2].rights = 890
                that.image_list51[3].rights = 1270
                that.image_list51[0].right = 130
                that.image_list51[1].right = 510
                that.image_list51[2].right = 890
                that.image_list51[3].right = 1270
                that.image_list51_answer[0].right = 130
                that.image_list51_answer[1].right = 510
                that.image_list51_answer[2].right = 890
                that.image_list51_answer[3].right = 1270
            } else if (that.image_list51.length == 5) {
                that.image_list51[0].rights = 40
                that.image_list51[1].rights = 380
                that.image_list51[2].rights = 720
                that.image_list51[3].rights = 1060
                that.image_list51[4].rights = 1400
                that.image_list51[0].right = 40
                that.image_list51[1].right = 380
                that.image_list51[2].right = 720
                that.image_list51[3].right = 1060
                that.image_list51[4].right = 1400
                that.image_list51_answer[0].right = 40
                that.image_list51_answer[1].right = 380
                that.image_list51_answer[2].right = 720
                that.image_list51_answer[3].right = 1060
                that.image_list51_answer[4].right = 1400
            }
            that.$forceUpdate()
        },
        /**
         * 重新排列
         * */
        rearrange(i) {
            let that = this
            if(i == 64) {
                that.permutationNum = 1
                that.num = 0
                that.image_list51_answer = []
                that.DragResize = false
                // this.image_list51 = []
                // this.image_list51 = this.topic.answer
                that.$nextTick(() => {
                    that.image51Wei()
                })
                that.image_list51.map((item,index) => {
                    item.tops = 180
                    item.num = 1
                    item.top = 180
                })
                for (let a = 0; a < that.image_list51.length; a++) {
                    let b = {
                        num: a + 1,
                        top: 440,
                        active:false,
                        img:null
                    }
                    that.image_list51_answer.push(b)
                }
                that.DragResize = true
                setTimeout(() => {
                    that.permutationNum = 0
                },1000)
                let answerJson = {
                    image_list51:that.image_list51,
                    image_list51_answer:that.image_list51_answer
                }
                that.websocketsend('1000'+that.sendData+'@'+'pincou'+JSON.stringify(answerJson));

                // console.log('this.image_list51',this.topic.answer)
            } else if (i == 53) {
                that.presentInterface()
            }
            that.$forceUpdate()
        },
        /**
         * 当前题目接口
         * */
        async presentInterface() {
            let params = {
                exam_id: this.$route.query.exam_id,
                patient_id:this.$route.query.patient_id,
                question_id: this.topic.id,
            }
            await this.$axios.post('api/TrainExam/now', params).then(res => {
                this.topic = res.data.data;
                this.options = Number(this.topic.answer_id);
                this.answer_page()
            })
        },
        /**
         * 副屏点击
         * */
        // async createOtherWin(){
        //     this.$electron.ipcRenderer.invoke('win-subScreen', {
        //         open: true,
        //         path: '#'
        //     })
        //     //this.$electron.ipcRenderer.invoke('win-subScreen-message', {name:'小宇',sex:'女'})
        //     //state.open = !state.open
        // },
        /**
         * 点击放大
         * */
        sizeBig() {
            let titleNum = parseFloat(document.getElementById('title').style.fontSize);
            let radioNum = parseFloat(document.getElementById('radio').style.fontSize);
            titleNum = titleNum + 1;
            radioNum = radioNum + 1;
            document.getElementById('title').style.fontSize = titleNum + 'px';
            document.getElementById('radio').style.fontSize = radioNum + 'px';
            if (titleNum > 50 && radioNum > 48) {
                document.getElementById('title').style.fontSize = 50 + 'px';
                document.getElementById('radio').style.fontSize = 48 + 'px';
            }
        },
        /**
         * 点击缩小
         * */
        sizeSmall() {
            let titleNum = parseFloat(document.getElementById('title').style.fontSize);
            let radioNum = parseFloat(document.getElementById('radio').style.fontSize);
            titleNum = titleNum - 1;
            radioNum = radioNum - 1;
            document.getElementById('title').style.fontSize = titleNum + 'px';
            document.getElementById('radio').style.fontSize = radioNum + 'px';
            if (titleNum < 14 && radioNum < 12) {
                document.getElementById('title').style.fontSize = 14 + 'px';
                document.getElementById('radio').style.fontSize = 12 + 'px';
            }
        },
        /**
         * 倒计时
         * */
        countDowm() {
            let self = this
            clearInterval(this.promiseTimer)
            this.promiseTimer = setInterval(function () {
                if (self.hour === 0) {
                    if (self.minute !== 0 && self.second === 0) {
                        self.second = 59
                        self.minute -= 1
                    } else if (self.minute === 0 && self.second === 0) {
                        self.second = 0
                        self.$emit('countDowmEnd', true)
                        clearInterval(self.promiseTimer)
                        self.$router.push(
                            {
                                name: 'TerminalEnd',
                                query: {
                                    exam_id: this.$route.query.exam_id,
                                    patient_id:this.$route.query.patient_id,
                                    music_time: self.music_time,
                                }
                            }
                        )
                    } else {
                        self.second -= 1
                    }
                } else {
                    if (self.minute !== 0 && self.second === 0) {
                        self.second = 59
                        self.minute -= 1
                    } else if (self.minute === 0 && self.second === 0) {
                        self.hour -= 1
                        self.minute = 59
                        self.second = 59
                    } else {
                        self.second -= 1
                    }
                }
            }, 1000)
        },
        formatNum(num) {
            return num < 10 ? '0' + num : '' + num
        },
        /**
         * 题目页面显示
         * */
        answer_page() {
            if(this.screenQ_id !== this.topic.id) {
                this.isQuestion = false
            }
            if(this.topic.type == 50) {
                if(this.topic.p_id == 0) {
                    this.options_50 = this.topic.answer_id
                } else {
                    this.options_50 = this.topic.p_id
                    this.options_50_display()
                }
            } else if (this.topic.type == 64) {
                this.image_list51 = []
                this.image_list51_answer = []
                this.image_list51 = this.topic.answer
                if (this.topic.answer_id == '') {
                    this.image_list51.map((item,index) => {
                        item.tops = 180
                        item.top = 180
                    })
                    for (let a = 0; a < this.image_list51.length; a++) {
                        let b = {
                            num: a + 1,
                            top: 440
                        }
                        this.image_list51_answer.push(b)
                    }
                    this.image51Wei()
                } else {
                    let answer_id = this.topic.answer_id.split(',');
                    for (let a = 0; a < this.image_list51.length; a++) {
                        for (let c = 0; c < answer_id.length; c++) {
                            if (this.image_list51[a].id == answer_id[c]) {
                                this.image_list51[a].num = 0
                            }
                        }
                        let b = {
                            num: a + 1
                        }
                        this.image_list51_answer.push(b)
                    }
                    this.image_list51_answer.map((item, index) => {
                        this.image_list51_answer[index].id = answer_id[index]
                    })
                    for (let a = 0; a < this.image_list51.length; a++) {
                        for (let c = 0; c < this.image_list51_answer.length; c++) {
                            if (this.image_list51_answer[c].id == this.image_list51[a].id) {
                                this.image_list51_answer[c].img = this.image_list51[a].img
                            }
                        }
                    }
                    this.num = answer_id.length
                }
            }else if (this.topic.type == 63 || this.topic.type == 65) {
                if(this.topic.answer_id !== '') {
                    this.image_list53 = this.topic.image_list52
                    this.image_list53.map((item,index) => {
                        // item.right = Number(item.right)
                        // item.top = Number(item.top)
                        item.rights = Number(item.rights)
                        item.tops = Number(item.tops)
                        item.rotation = Number(item.rotation)
                        item.isDragging = false
                        item.startX = 0
                        item.startY = 0
                        item.startAngle = null
                    })
                    this.$nextTick(() => {
                        const images = this.$refs.img; // 获取需要手势操作的元素
                        this.image_list53.map((item,index) => {
                            images[index].style.transform = `translate3d(${item.rights}px, ${item.tops}px, 0) rotate(${item.rotation}deg)`
                        })
                        this.rotateChange()
                    })
                    if(this.topic.type == 65) {
                        this.type58id = 1
                    }
                } else {
                    if(this.topic.type == 65 && this.topic.answer_score && this.topic.example !== 1) {
                        console.log('1111111111')
                        this.image_list53 = this.topic.image_list52
                        this.image_list53.map((item,index) => {
                            // item.right = Number(item.right)
                            // item.top = Number(item.top)
                            item.rights = Number(item.rights)
                            item.tops = Number(item.tops)
                        })
                        this.$nextTick(() => {
                            const images = this.$refs.img; // 获取需要手势操作的元素
                            this.image_list53.map((item,index) => {
                                images[index].style.transform = `translate3d(${item.rights}px, ${item.tops}px, 0) rotate(${item.rotation}deg)`
                            })
                            this.rotateChange()
                        })
                        if(Number(this.topic.answer_score) >= 0 && Number(this.topic.answer_score) < 10) {
                            this.type58id = 2
                            this.score58id = Number(this.topic.answer_score)
                        }

                    } else {
                        console.log('2222222')
                        this.image_list53 = this.topic.answer
                        this.image_list53.map((item,index) => {
                            // item.right = Number(item.right)
                            // item.top = Number(item.top)
                            item.rights = Number(item.rights)
                            item.tops = Number(item.tops)
                            // item.transform = ''
                            item.rotation = 0
                            item.isDragging = false
                            item.startX = 0
                            item.startY = 0
                            item.startAngle = null
                        })
                    }

                    if((this.topic.type == 63 || this.topic.type == 65) && !this.zhidaoyuDjs) {
                        console.log('33333')
                        this.$nextTick(() => {
                            this.rotateChange()
                        })

                    }
                }
                // console.log('111111',this.image_list53)
                // if(this.topic.type == 65) {
                //     if (this.topic.answer_id.length<0) {
                //         this.image_list53_answer = []
                //     } else {
                //         let answer_id = this.topic.answer_id.split(',');
                //         this.image_list53_answer = answer_id
                //     }
                //
                // }

            } else if(this.topic.type == 52) {
                if(this.topic.answer_id == '') {
                    for (let a = 0; a < 12; a++) {
                        let b = {
                            num: a + 1,
                            color:'#D3D3D3'
                        }
                        this.image_list52.push(b)
                    }
                } else {
                    this.image_list52 = this.topic.image_list52
                }

                // if(this.topic.p_id == 0) {
                //     this.options_50 = this.topic.answer_id
                // } else {
                //     this.options_50 = this.topic.p_id
                //     this.options_50_display()
                // }
            } else if(this.topic.type == 62) {
                let answer_id = this.topic.answer_id
                if(answer_id.length > 0) {
                    // console.log('answer_id',answer_id)
                    this.topic.answer.map((item,index) => {
                        item.child.map((a,b) => {
                            // console.log('a.id',a.id)
                            // console.log('answer_id.includes(a.id)',answer_id.includes(a.id))
                            if(answer_id.includes(a.id)) {
                                a.show = true
                            }
                        })
                    })
                }
                // console.log('this.topic.answer',this.topic.answer)
            }
            if(this.topic.type == 62 || this.topic.type == 63 || this.topic.type == 64 || this.topic.type == 65) {
                setTimeout(() => {
                    console.log('主屏渲染完成')
                    let answerJson = {
                        id:this.topic.id,
                    }
                    this.websocketsend('1000'+this.sendData+'@'+'isQues'+JSON.stringify(answerJson))
                },2000)
            } else {
                let answerJson = {
                    id:this.topic.id,
                }
                this.websocketsend('1000'+this.sendData+'@'+'isQues'+JSON.stringify(answerJson))
            }
        },
        /**
         * 获取题目
         * */
        async getTopic() {
            this.answerList = []
            this.image_list51_answer = []
            this.image_list53 = []
            // this.image_list53_answer = []
            this.score58id = ''
            this.type58id = ''
            this.num = 0
            let params = {
                exam_id: this.$route.query.exam_id,
                patient_id:this.$route.query.patient_id,
            }
            await this.$axios.post('api/TrainExam/start', params).then(res => {
                this.$electron.ipcRenderer.invoke('win-subScreen', {
                    open: true,
                    path: '#'
                })
                this.init()
                this.topic = res.data.data;
                this.options = Number(this.topic.answer_id);
                if(res.data.data.zhidaoyu) {
                    this.zhidaoyuDjs = true
                    // this.readTopic(res.data.data.zhidaoyu)
                    // var timerzdy = setInterval(() => {
                    //     if (this.zhidaoyuDjs == 0) {
                    //         this.exam_end();
                    //         clearInterval(timerzdy)
                    //         this.readTopic()
                    //     } else {
                    //         this.zhidaoyuDjs--;
                    //     }
                    // }, 1000)
                } else {
                    // this.readTopic()
                    setTimeout(() => {
                        this.answer_page()
                    },1000)
                }

            })
        },
        /**
         * 重复语言点击
         * */
        readTopicRepeat() {
            this.websocketsend('1000'+this.sendData+'@'+'read_Topic');
        },
        /**
         * 重复语言
         * */
        readTopic(zhidaoyu) {
            // console.log('zhidaoyu',zhidaoyu)
            if(zhidaoyu) {
                this.presetVoice();
                var synth = window.speechSynthesis;
                window.speechSynthesis.cancel();
                var utterThis = new SpeechSynthesisUtterance(this.zhidaoyuVoice);
                utterThis.volume = 1  // 声音的音量
                utterThis.rate = 1 // 语速，数值，默认值是1，范围是0.1到10
                utterThis.pitch = 1 // 表示说话的音高，数值，范围从0（最小）到2（最大）。默认值为1
                synth.speak(utterThis);
                // utterThis = new SpeechSynthesisUtterance('请听答案：' + this.answerVoice);
                // utterThis.volume = 1  // 声音的音量
                // utterThis.rate = 1 // 语速，数值，默认值是1，范围是0.1到10
                // utterThis.pitch = 1 // 表示说话的音高，数值，范围从0（最小）到2（最大）。默认值为1
                // synth.speak(utterThis);
                // console.log('读完22222')
                // 播放结束后调用
                utterThis.onend = (event)=>{
                    this.exam_end()
                }
            } else {
                this.presetVoice();
                var synth = window.speechSynthesis;
                window.speechSynthesis.cancel();
                var utterThis = new SpeechSynthesisUtterance(this.titleVoice);
                utterThis.volume = 1  // 声音的音量
                utterThis.rate = 1 // 语速，数值，默认值是1，范围是0.1到10
                utterThis.pitch = 1 // 表示说话的音高，数值，范围从0（最小）到2（最大）。默认值为1
                synth.speak(utterThis);
                // utterThis = new SpeechSynthesisUtterance('请听答案：' + this.answerVoice);
                // utterThis.volume = 1  // 声音的音量
                // utterThis.rate = 1 // 语速，数值，默认值是1，范围是0.1到10
                // utterThis.pitch = 1 // 表示说话的音高，数值，范围从0（最小）到2（最大）。默认值为1
                // synth.speak(utterThis);
                // console.log('读完22222')
                // 播放结束后调用
                utterThis.onend = (event)=>{
                    // console.log('11111111111111111')
                }
            }


        },
        presetVoice() {
            // this.titleVoice = this.topic.title.split('.')[1];
            this.titleVoice = this.topic.intro.replace(/\.|-|\(|\)/g, '、')
            if(this.topic.zhidaoyu) {
                this.zhidaoyuVoice =  this.topic.zhidaoyu.replace(/\.|-|\(|\)/g, '、')
            }
            let a = this.topic.answer.map(item => {
                return item.text
            })
            this.answerVoice = a.join(',').split('.').map((item, index) => {
                if (index === a.length - 1) {
                    return item
                } else {
                    return item + '。'
                }
            }).join(',');
        },
        /**
         * 答题结束
         * */
        answer_end() {
            this.options_50 = ''
            this.answerList = []
            this.image_list52 = []
            this.image_list53 = []
            this.image_list53_answer = []
            this.score58id = ''
            this.type58id = ''
            if (this.topic.all_count > this.topic.now_count) {
                this.nextTopic()
            } else if (this.topic.now_count == this.topic.all_count) {
                // console.log('结束1111')
                // this.readTopic('')
                this.clearTimerWeb()
                setTimeout(() => {
                    this.websocketsend('1000'+this.sendData+'@'+'jieshu');
                },1000)
                window.speechSynthesis.cancel()
                this.$router.push({
                    name: 'TerminalEnd',
                    query: {
                        exam_id: this.$route.query.exam_id,
                        patient_id:this.$route.query.patient_id,
                    }
                })


            }
        },
        /**
         * 答题
         * */
        answerJudgment(answer_id) {
            if(!this.isQuestion) {
                this.$message.error('当前主副屏未同步，请稍后进行作答!')
                let answerJson = {
                    id:this.topic.id,
                }
                this.websocketsend('1000'+this.sendData+'@'+'tongBu'+JSON.stringify(answerJson))
                return
            }
            if(this.topic.type == 59) {
                let params = {
                    exam_id: this.$route.query.exam_id,
                    patient_id:this.$route.query.patient_id,
                    question_id: this.topic.id,
                    answer_id: answer_id,
                }
                this.$axios.post('api/TrainExam/aswer', this.$qs.stringify(params)).then(res => {
                    if (res.data.code === 1) {
                        this.answer_end()
                        // this.clearTimerWeb()
                        // this.websocketsend('1000'+this.sendData+'@'+'answer_end');
                        // setTimeout(() => {
                        //     this.websockTimer()
                        // },1000)
                    } else {
                        this.$message.error(res.data.info)
                    }
                })
            } else if (this.topic.type == 50) {
                let answerList = []
                this.topic.answer.map((item,index) => {
                    if(item.id == answer_id) {
                        answerList = item.child
                    }
                })
                if(answerList.length > 0) {
                    this.options_50 = answer_id
                    this.answerList = answerList
                } else {
                    let params = {
                        exam_id: this.$route.query.exam_id,
                        patient_id:this.$route.query.patient_id,
                        question_id: this.topic.id,
                        answer_id: answer_id,
                    }
                    this.$axios.post('api/TrainExam/aswer', this.$qs.stringify(params)).then(res => {
                        if (res.data.code === 1) {
                            this.answer_end()
                            // this.clearTimerWeb()
                            // this.websocketsend('1000'+this.sendData+'@'+'answer_end');
                            // setTimeout(() => {
                            //     this.websockTimer()
                            // },1000)

                        } else {
                            this.$message.error(res.data.info)
                        }
                    })
                }
            } else if (this.topic.type == 64) {
                // this.num++;
                // this.image_list51_answer.map((item,index) => {
                //     if(item.num == this.num) {
                //         item.img = answer_id.img
                //         item.id = answer_id.id
                //     }
                // })
                // this.image_list51.map((item,index) => {
                //     if(item.id == answer_id.id) {
                //         item.num = 0
                //     }
                // })
                // this.$forceUpdate()
            } else if (this.topic.type == 52) {
                this.image_list52.map((item,index) => {
                    if(item.num == answer_id) {
                        item.color = '#F76664'
                    }
                })
                this.websocketsend('1000'+this.sendData+'@'+'colors'+JSON.stringify(this.image_list52))

                this.$forceUpdate()
            } else if(this.topic.type == 61 || this.topic.type == 60) {
                let params = {
                    exam_id: this.$route.query.exam_id,
                    patient_id:this.$route.query.patient_id,
                    question_id: this.topic.id,
                    answer_id: answer_id,
                }
                this.$axios.post('api/TrainExam/aswer', this.$qs.stringify(params)).then(res => {
                    if (res.data.code === 1) {
                        this.answer_end()
                        // this.clearTimerWeb()
                        // this.websocketsend('1000'+this.sendData+'@'+'answer_end');
                        // setTimeout(() => {
                        //     this.websockTimer()
                        // },1000)

                    } else {
                        this.$message.error(res.data.info)
                    }
                })
            }
        },
        /**
         * 分类测验二级点击
         * */
        answer50(answer_id) {
            if(!this.isQuestion) {
                this.$message.error('当前主副屏未同步，请稍后进行作答!')
                let answerJson = {
                    id:this.topic.id,
                }
                this.websocketsend('1000'+this.sendData+'@'+'tongBu'+JSON.stringify(answerJson))
                return
            }
            let params = {
                exam_id: this.$route.query.exam_id,
                patient_id:this.$route.query.patient_id,
                question_id: this.topic.id,
                answer_id: answer_id,
            }
            this.$axios.post('api/TrainExam/aswer', this.$qs.stringify(params)).then(res => {
                if (res.data.code === 1) {
                    this.answer_end()
                    // this.clearTimerWeb()
                    // this.websocketsend('1000'+this.sendData+'@'+'answer_end');
                    // setTimeout(() => {
                    //     this.websockTimer()
                    // },1000)

                } else {
                    this.$message.error(res.data.info)
                }
            })
        },
        /**
         * 拼图选项点击
         * */
        answer58(answer_id) {
            if(!this.isQuestion) {
                this.$message.error('当前主副屏未同步，请稍后进行作答!')
                let answerJson = {
                    id:this.topic.id,
                }
                this.websocketsend('1000'+this.sendData+'@'+'tongBu'+JSON.stringify(answerJson))
                this.type58id = ''
                return
            }
            if (answer_id == 1) {
                let a = []
                this.topic.answer.map((item,index) => {
                    a.push(item.id)
                })
                const images = this.$refs.img; // 获取需要手势操作的元素
                // this.image_list53.map((item,index) => {
                //     item.transform = images[index].style.transform
                // })
                let params = {
                    exam_id: this.$route.query.exam_id,
                    patient_id:this.$route.query.patient_id,
                    question_id: this.topic.id,
                    answer_id: String(a),
                    image_list52:this.image_list53
                }
                this.$axios.post('api/TrainExam/aswer', this.$qs.stringify(params)).then(res => {
                    if (res.data.code === 1) {
                        this.answer_end()
                        // this.clearTimerWeb()
                        // this.websocketsend('1000'+this.sendData+'@'+'answer_end');
                        // setTimeout(() => {
                        //     this.websockTimer()
                        // },1000)

                    } else {
                        this.$message.error(res.data.info)
                    }
                })
            }
        },
        /**
         * 拼图 错误答案
         * */
        score58answer(answer_id) {
            if(!this.isQuestion) {
                this.$message.error('当前主副屏未同步，请稍后进行作答!')
                let answerJson = {
                    id:this.topic.id,
                }
                this.websocketsend('1000'+this.sendData+'@'+'tongBu'+JSON.stringify(answerJson))
                return
            }
            const images = this.$refs.img; // 获取需要手势操作的元素
            // this.image_list53.map((item,index) => {
            //     item.transform = images[index].style.transform
            // })
            let params = {
                exam_id: this.$route.query.exam_id,
                patient_id:this.$route.query.patient_id,
                question_id: this.topic.id,
                answer_id: '',
                score:answer_id,
                image_list52:this.image_list53
            }
            this.$axios.post('api/TrainExam/aswer', this.$qs.stringify(params)).then(res => {
                if (res.data.code === 1) {
                    this.answer_end()
                    // this.clearTimerWeb()
                    // this.websocketsend('1000'+this.sendData+'@'+'answer_end');
                    // setTimeout(() => {
                    //     this.websockTimer()
                    // },1000)

                } else {
                    this.$message.error(res.data.info)
                }
            })
        },
        /**
         * 选择题答题
         * */
        answer(answer_id) {
            if(!this.isQuestion) {
                this.options = ''
                this.$message.error('当前主副屏未同步，请稍后进行作答!')
                let answerJson = {
                    id:this.topic.id,
                }
                this.websocketsend('1000'+this.sendData+'@'+'tongBu'+JSON.stringify(answerJson))
                return
            }
            if(this.topic.type == 52) {
                let params = {
                    exam_id: this.$route.query.exam_id,
                    patient_id:this.$route.query.patient_id,
                    question_id: this.topic.id,
                    answer_id: answer_id,
                    image_list52:this.image_list52
                }
                this.$axios.post('api/TrainExam/aswer', this.$qs.stringify(params)).then(res => {
                    if (res.data.code === 1) {
                        this.answer_end()
                        // this.clearTimerWeb()
                        // this.websocketsend('1000'+this.sendData+'@'+'answer_end');
                        // setTimeout(() => {
                        //     this.websockTimer()
                        // },1000)

                    } else {
                        this.$message.error(res.data.info)
                    }
                })
            } else if(this.topic.type == 63) {
                let params = {
                    exam_id: this.$route.query.exam_id,
                    patient_id:this.$route.query.patient_id,
                    question_id: this.topic.id,
                    answer_id: answer_id,
                    image_list52:this.image_list53
                }
                this.$axios.post('api/TrainExam/aswer', this.$qs.stringify(params)).then(res => {
                    if (res.data.code === 1) {
                        this.answer_end()
                        // this.clearTimerWeb()
                        // this.websocketsend('1000'+this.sendData+'@'+'answer_end');
                        // setTimeout(() => {
                        //     this.websockTimer()
                        // },1000)

                    } else {
                        this.$message.error(res.data.info)
                    }
                })
            } else {
                let params = {
                    exam_id: this.$route.query.exam_id,
                    patient_id:this.$route.query.patient_id,
                    question_id: this.topic.id,
                    answer_id: answer_id,
                }
                this.$axios.post('api/TrainExam/aswer', this.$qs.stringify(params)).then(res => {
                    if (res.data.code === 1) {
                        this.answer_end()
                        // this.clearTimerWeb()
                        // this.websocketsend('1000'+this.sendData+'@'+'answer_end');
                        // setTimeout(() => {
                        //     this.websockTimer()
                        // },1000)

                    } else {
                        this.$message.error(res.data.info)
                    }
                })
            }

        },
        /**
         * 下一题点击
         * */
        async nextTopic(text) {
            if(text) {
                this.websocketsend('1000'+this.sendData+'@'+'next_topic');

            }
            this.options_50 = ''
            this.answerList = []
            this.image_list51_answer = []
            this.image_list52 = []
            this.image_list51 = []
            this.image_list53 = []
            this.score58id = ''
            this.type58id = ''
            this.num = 0
            if (this.topic.all_count == this.topic.now_count) {
                // console.log('结束')
                this.$message.error('已经是最后一题了!')
                return
            }
            let params = {
                exam_id: this.$route.query.exam_id,
                patient_id:this.$route.query.patient_id,
                question_id: this.topic.now_count == this.topic.all_count ? 0 : this.topic.id,
            }
            let answer ={
                id:this.topic.id
            }
            await this.$axios.post('api/TrainExam/next', this.$qs.stringify(params)).then(res => {
                // console.log('res.data.data',res.data.data)
                if(res.data.data.type) {
                    // console.log(res)
                    this.topic = res.data.data;
                    this.options = Number(this.topic.answer_id);
                    this.$forceUpdate()
                    if(res.data.data.zhidaoyu) {
                        this.zhidaoyuDjs = true
                        // this.readTopic(res.data.data.zhidaoyu)
                        // this.zhidaoyuDjs = 10
                        // // clearInterval(this.timerzdy)
                        // // this.timerzdy = null
                        // var timerzdy = setInterval(() => {
                        //     if (this.zhidaoyuDjs == 0) {
                        //         this.exam_end();
                        //         clearInterval(timerzdy)
                        //         this.readTopic()
                        //     } else {
                        //         this.zhidaoyuDjs--;
                        //     }
                        // }, 1000)
                    } else {
                        this.answer_page()
                        // this.readTopic()
                    }
                } else {
                    // console.log('结束2222')
                    window.speechSynthesis.cancel()
                    this.options_50 = ''
                    this.answerList = []
                    this.image_list52 = []
                    this.image_list53 = []
                    this.image_list53_answer = []
                    this.$router.push({
                        name: 'TerminalEnd',
                        query: {
                            exam_id: this.$route.query.exam_id,
                            patient_id:this.$route.query.patient_id,
                        }
                    })
                }
                if(!text) {
                    this.websocketsend('1000'+this.sendData+'@'+'naxtTi'+JSON.stringify(answer));

                }

            })
        },
        /**
         * 分类测验一级点击完后二级内容
         * */
        options_50_display() {
            if(!this.isQuestion) {
                this.$message.error('当前主副屏未同步，请稍后进行作答!')
                let answerJson = {
                    id:this.topic.id,
                }
                this.websocketsend('1000'+this.sendData+'@'+'tongBu'+JSON.stringify(answerJson))
                return
            }
            let answerList = []
            this.topic.answer.map((item,index) => {
                if(item.id == this.topic.p_id) {
                    answerList = item.child
                }
            })
            if(answerList.length > 0) {
                this.answerList = answerList
            }
        },
        /**
         * 点击上一题
         * */
        async prevTopic(text) {
            if(!this.isQuestion) {
                this.$message.error('当前主副屏未同步，请稍后进行作答!')
                let answerJson = {
                    id:this.topic.id,
                }
                this.websocketsend('1000'+this.sendData+'@'+'tongBu'+JSON.stringify(answerJson))
                return
            }
            if(text) {
                this.websocketsend('1000'+this.sendData+'@'+'prev_topic');

            }
            this.answerList = []
            this.image_list51_answer = []
            this.num = 0
            this.image_list52 = []
            this.image_list51 = []
            this.image_list53 = []
            if (this.topic.now_count == 1) {
                this.$message.error('已经是第一题了!')
                return;
            }
            let params = {
                exam_id: this.$route.query.exam_id,
                patient_id:this.$route.query.patient_id,
                question_id: this.topic.id,
            }
            await this.$axios.post('api/TrainExam/upper', this.$qs.stringify(params)).then(res => {
                this.topic = res.data.data;
                this.options = Number(this.topic.answer_id);
                this.answer_page()
                this.$forceUpdate()
                // this.readTopic();
            })
        },
        // 退出
        quitExam() {
            this.$confirm('确定退出吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.clearTimerWeb()
                this.$axios.post('api/TrainExam/suspend', {exam_id: this.$route.query.exam_id,}).then(res => {
                    this.$message.success(res.data.info);
                    window.history.go(-1);
                    this.$electron.ipcRenderer.invoke('win-subScreen', {
                        open: false,
                        path: '#'
                    })
                })
                window.speechSynthesis.cancel()
            })
        },
        /**
         * 结束按钮点击事件
         * */
        endExam() {
            this.$confirm('确定结束吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.clearTimerWeb()
                setTimeout(() => {
                    this.websocketsend('1000'+this.sendData+'@'+'endEndExam');
                },1000)
                // setTimeout(() => {
                //     this.websockTimer()
                // },1000)
                window.speechSynthesis.cancel()
                this.$router.push(
                    {
                        name: 'TerminalEnd',
                        query: {
                            exam_id: this.$route.query.exam_id,
                            patient_id:this.$route.query.patient_id,
                        }
                    }
                )
            })

        },
    },
}
</script>

<style scoped lang="scss">
.image-wrapper {
    position: absolute;
    //width: 240px;
    //height: 240px;
    //overflow: hidden;
}
.drag-image {
    width: 100%;
    height: 100%;
    //object-fit: cover;
}
.submitButton{
    width: 221px;
    height: 72px;
    background: linear-gradient(180deg, #8197FE 0%, #6B9FFC 100%);
    box-shadow: 0px 6px 0px 0px #377CF8;
    border-radius: 36px;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 72px;
    position: absolute;
    right: 150px;
    bottom: 200px;
    cursor: pointer;
}
// 取消默认外边框虚线
.vdr.active:before {
    display:none;
}
.topic_53_box {
    .options-judgment-box53 {
        width: 450px;
        height: 480px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        position: absolute;
        left: 200px;
        top: 150px;
    }
    .image_53_back {
        position: absolute;
        right: 250px;
        top: 250px;
    }
    .type58Box {
        width: 320px;
        max-height: 800px;
        min-height: 300px;
        padding: 40px;
        background: #FFFFFF;
        box-shadow: 0px 0px 20px 0px rgba(205,205,205,0.3);
        border-radius: 30px;
        position: absolute;
        right: 80px;
        top:50px;
        //bottom: 150px;
    }
    .option_button {
        position: absolute;
        right: 80px;
        bottom: 150px;
        ::v-deep .el-radio__input.is-checked + .el-radio__label{
            color: #F76664!important;

        }
        ::v-deep .el-radio__input.is-checked .el-radio__inner {
            border-color: #F76664!important;
            background-color: #F76664!important;
        }
        .el-radio-group {
            width: 580px;
            flex-wrap: nowrap;
            display: flex;
            .el-radio {
                width: 222px;
                height: 78px;
                line-height: 78px;
                background: linear-gradient(180deg, #8197FE 0%, #6B9FFC 100%);
                box-shadow: 0px 6px 0px 0px #377CF8;
                border-radius: 34px 35px 35px 34px;
                margin-right: 0;
                margin-left: 70px;
                margin-bottom: 10px;
                font-size: 25px;
                font-weight: bold;
                color: #FFFFFF;
                display: flex;
                justify-content: center;
            }
        }

    }
}

.options-list {
    width: 100%;
    height: 530px;
    margin-top: 20px;
    font-size: 20px;
}

.options52_box {
    margin-left: 300px;
    width: 700px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    .options52_box_child {
        width: 150px;
        height: 150px;
        //background: #D3D3D3;
        border-radius: 2px;
        margin-bottom: 35px;
    }

}
.option_button {
    display: flex;
    float: right;
    margin-right: 100px;
    .prev {
        background: linear-gradient(180deg, #8197FE 0%, #6B9FFC 100%);
        box-shadow: 0px 6px 0px 0px #377CF8;
        border-radius: 34px 35px 35px 34px;
        margin-left: 70px;
    }
}
.image_box_none {
    width: 333px;
    height: 202px;
}
.image_box {
    width: 333px;
    height: 202px;
    background: #FFFFFF;
    line-height: 202px;
    text-align: center;
    font-size: 50px;
    font-weight: bold;
    border: 1px solid #979797;
}
.options-img-judgment_answer {
    display: block;
    //width: 333px;
    height: 202px;
    margin: 0 auto;

}

.options-judgment-box {
    width: 100%;
    .options-img-judgment {
        display: block;
        //width: 333px;
        height: 202px;
        margin: 0 auto;
    }
    .options-judgment-box-answer {
        margin: 0 auto;
        //margin-top: 100px;
        display: flex;
        justify-content: space-around;
    }
    .answerQuestions_image {
        width: 333px;
        height: 201px;
        position: absolute;
        top: 1px;
        left: 0;
        z-index: 1;
    }
}
::v-deep {
    .el-radio-group {
        width: 100%;
        //display: flex;
        //flex-wrap: wrap;
        //align-items: flex-start;
    }
    .el-radio {
        //width: 48%;
        display: flex;
        align-items: center;
        color: #000000;
        margin-bottom: 25px;
        white-space: pre-wrap;
        font-size: inherit;
        line-height: 30px;
    }

    .el-radio__label {
        font-size: inherit !important;
    }
}
::v-deep .el-checkbox__label {
    margin-bottom: 40px;
    color: #333333;
    font-weight: 500;
    font-size: 22px !important;
}
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
    font-weight: 500;
    font-size: 22px;
    color: #8197FE;
}
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
    border-color: #8197FE;
    background: #8197FE;
}
::v-deep .el-radio__input.is-checked + .el-radio__label {
    color: #8197FE;
}
::v-deep .el-radio__input.is-checked .el-radio__inner {
    border-color: #8197FE;
    background: #8197FE;
}
.segment_list {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .segment {
        margin-left: -300px;
        display: flex;
        position: absolute;
        .line_text {
            width: 100px;
            text-align: center;
            font-size: 20px;
            color: #999999;
            position: relative;
            top: 10px;
            left: 50%;
            cursor: pointer;
        }
        .line_box {
            width: 100px;
            height: 10px;
            border: 1px solid #999999;
            border-top: none;
        }
        .extend1 {
            width: 120px;
            text-align: center;
            position: relative;
            top: -50px;
            left: 13%;
            font-size: 24px;
        }
        .extend2 {
            width: 120px;
            text-align: center;
            position: relative;
            top: -50px;
            left: 87%;
            font-size: 24px;
        }
    }
}
.terminal-bg-zdy {
    width: 100vw;
    height: 100vh;
    background-image: url(../assets/images/zdy.png);
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    //padding: 77px 161px;
    overflow-y: scroll;
    .zdyText {
        font-size: 77px;
        font-weight: bold;
        color: #8197FE;
        text-align: center;
        margin-top: 80px;
    }
    .button-wrap-zdy {
        //width: 100%;
       position: absolute;
        bottom: 100px;

        //> span {
        //    position: absolute;
        //    font-size: 22px;
        //    right: 0;
        //}
        .submit-question {
            width: 222px;
            height: 78px;
            margin: 0 auto;
            //background-size: 100% 100%;
            //background-position: center;
            //background-repeat: no-repeat;
            line-height: 78px;
            font-size: 25px;
            font-weight: bold;
            color: #FFFFFF;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            //background-image: url(../../assets/images/button4.png);
            background: linear-gradient(180deg, #8197FE 0%, #6B9FFC 100%);
            box-shadow: 0px 6px 0px 0px #377CF8;
            border-radius: 34px 35px 35px 34px;
            img {
                width: 28px;
                height: 28px;
                margin-left: 15px;
            }
        }
    }
}
.terminal-bg {
    width: 100vw;
    height: 100vh;
    background-image: url(../assets/images/tp.png);
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    padding: 77px 161px;
    overflow-y: scroll;
}

.sheet {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -45%);
    width: 1750px;
    height: 920px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.69) 0%, #FFFFFF 100%);
    padding: 40px 80px 0;
}

.page-count {
    position: absolute;
    right: 0;
    top: 0;
    transform: translateY(-100%);
    font-size: 54px;
    color: #8197FE;
    display: flex;
    align-items: center;

    > span {
        color: #8197FE;
        font-size: 30px;
        margin-top: 10px;
    }

}
::v-deep .el-button > span {
    display: flex;
    justify-content: center;
    align-items: center;
}

//.submit-question {
//    background: linear-gradient(0deg, #32D25B 0%, #FA084E 0%, #FF669D 100%);
//    box-shadow: 0px 6px 0px 0px #D40A5D;
//    border-radius: 35px;
//    margin-left: 72px;
//
//    img {
//        margin-left: 10px;
//    }
//}

.button-wrap {
    // margin-top: 150px;
    // display: flex;
    // justify-content: space-around;
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 40px;
    //margin: 0 320px;
    display: flex;
    //justify-content: space-around;
    .el-radio-group {
        width: 580px;
        flex-wrap: nowrap;
        display: flex;
        .el-radio {
            width: 222px;
            height: 78px;
            line-height: 78px;
            background: linear-gradient(180deg, #8197FE 0%, #6B9FFC 100%);
            box-shadow: 0px 6px 0px 0px #377CF8;
            border-radius: 34px 35px 35px 34px;
            margin-right: 0;
            margin-left: 70px;
            margin-bottom: 10px;
            font-size: 25px;
            font-weight: bold;
            color: #FFFFFF;
            display: flex;
            justify-content: center;
        }
    }
}
.prev {
    width: 222px;
    height: 78px;
    //background-size: 100% 100%;
    //background-position: center;
    //background-repeat: no-repeat;
    line-height: 78px;
    font-size: 25px;
    font-weight: bold;
    color: #FFFFFF;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        width: 28px;
        height: 28px;
    }
}
.prev1 {
    background: linear-gradient(180deg, #5CB4F5 0%, #59B3F3 100%);
    box-shadow: 0px 6px 0px 0px #3EA3EA;
    border-radius: 34px 35px 35px 34px;
    //background-image: url(../assets/images/button2.png);
    img {
        margin-right: 15px;
    }
}
.prev2 {
    background: linear-gradient(180deg, #8197FE 0%, #6B9FFC 100%);
    box-shadow: 0px 6px 0px 0px #377CF8;
    border-radius: 34px 35px 35px 34px;
    img {
        margin-right: 15px;
    }
}
.prev3 {
    background: linear-gradient(180deg, #8197FE 0%, #6B9FFC 100%);
    box-shadow: 0px 6px 0px 0px #377CF8;
    border-radius: 34px 35px 35px 34px;
    img {
        margin-left: 15px;
    }
}
.prev4 {
    background: linear-gradient(180deg, #5CB4F5 0%, #59B3F3 100%);
    box-shadow: 0px 6px 0px 0px #3EA3EA;
    border-radius: 34px 35px 35px 34px;
    img {
        margin-left: 15px;
    }
}
.prev5 {
    background: linear-gradient(182deg, #F54D4F 0%, #FFAA9C 100%);
    box-shadow: 0px 6px 0px 0px #F86865;
    border-radius: 34px 35px 35px 34px;
}
.title {
    font-size: 24px;
    font-family: Microsoft YaHei;
    color: #000000;
    line-height: 45px;
    position: relative;
    > h3 {
        font-weight: normal !important;
    }

    > h3 > span {
        position: absolute;
        left: 0;
        top: 0;
        transform: translateX(-120%);
    }
}



.radio {
    width: 100%;
}

.radio-wrap {
    display: flex;
    flex-wrap: wrap;
}

.options-wrap {
    display: flex;
    align-items: center;
}

.options-img {
    height: 400px;
    width: auto;
}

.typeface {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-100%);
    cursor: pointer;
}

.typeface img {
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
    margin-right: 2px;
}

.timer {
    width: 200px;
    height: 37px;
    position: absolute;
    top: -13px;
    left: 50%;
    margin-left: -100px;
    transform: translateY(-100%);
    display: flex;
}

.timer img {
    width: 37px;
    height: 38px;
}

.timer span {
    font-size: 32px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #FFFFFF;
    margin-left: 12px;
}

.input {
    display: flex;
    text-align: center;
    line-height: 50px;
    width: 270px;
    height: 50px;

    input {
        width: 180px;
        line-height: 50px;
        font-size: 18px;
        border-bottom: 1px solid #333333;
    }
}
.input_ {
    display: flex;
    text-align: center;
    line-height: 50px;
    // width: 300px;
    height: 50px;

    input {
        width: 300px;
        line-height: 50px;
        font-size: 18px;
        border-bottom: 1px solid #333333;
    }
}
</style>
